<template>
  <div class="show-archived">
    <label for="show-archived">Show Archived</label>
    <input
      id="show-archived"
      type="checkbox"
      v-model="showArchived"
      @change="change"
    >
  </div>
</template>

<script>

export default {
  name: 'header-show-archived',
  data() {
    return {}
  },
  computed: {
    showArchived: {
      get() {
        return this.$store.getters.getShowArchived(this.$route.name)
      },
      set(value) {
        this.$store.dispatch('setShowArchived', { page: this.$route.name, query: value })
      }
    }
  },
  mounted() {
    console.log('Default:', this.$store.getters.getShowArchived(this.$route.name));
    this.showArchived = this.$store.getters.getShowArchived(this.$route.name) === 'init' ? true : this.$store.getters.getShowArchived(this.$route.name)
    this.$emitter.on('header_reset_filters', () => {
      this.showArchived = true
    })
  },
  beforeDestroy() {
    this.$emitter.off('header_reset_filters')
  },
  methods: {
    change() {
      setTimeout(() => {
        this.$emitter.emit('header_addons_changed')
        this.$emitter.emit('header_show_archived_addon_changed')
      }, 0);
    }
  }
}
</script>
