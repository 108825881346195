<template>
  <div class="hero-navigation">
    <div class="container">
      <div class="hero-navigation__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<style lang="scss" scoped>
.hero-navigation {
  margin-bottom: 15px;
  background-color: #F4F5FF;
  padding: 15px 0;
}
</style>
