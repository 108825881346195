<template>
  <div class="clients-settings-page">
    <settings-navigation />
    <div class="agGrid-page container">
      <base-header>
        <show-archived-addon />
      </base-header>
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :cellValueChanged="cellValueChanged"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import SettingsNavigation from '../components/SettingsNavigation.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// Custom Cell Renderers
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer.vue'
import SimpleSelectRenderer from '@/agGridV2/renderers/simple-select.renderer.vue'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import DatePickRenderer from '@/agGridV2/renderers/date-pick.renderer'

export default {
  components: {
    BaseHeader, ShowArchivedAddon,
    BaseAgGrid,
    SettingsNavigation,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SimpleSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    DatePickRenderer
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      customHtml: null,
      cellValueChanged: null
    }
  },
  async mounted() {
    const filters = await MtApi.getFilters({
      collectionName: 'projects',
      fields: ['industry']
    });
    this.columnDefs = [
      {
        field: 'name',
        headerName: 'Name'
      },
      {
        field: 'abbr_l',
        headerName: 'Abbreviation'
      },
      {
        field: 'type',
        headerName: 'Type'
      },
      {
        field: 'industry',
        headerName: 'Industry',
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          options: SimpleHelper.industries
        },
        cellStyle: { overflow: 'visible' },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filters['industry']
        }
      },
      {
        field: 'platforms',
        headerName: 'Platforms',
        editable: true,
        cellClassRules: {
          'not-editable': (params) => {
            return params.value === 'inapplicable'
          }
        },
        valueSetter: (params) => {
          const platform = params.newValue
          params.data.platforms = platform
          if (!platform) {
            return true
          }
          if (platform.includes('www.')) {
            this.$bvToast.toast('The platform should not contain www', {
              title: `Platform Alert`,
              variant: 'danger',
              solid: true
            })
            return false
          } else if (!platform.includes('.')) {
            this.$bvToast.toast('The platform must contain a point', {
              title: `Platform Alert`,
              variant: 'danger',
              solid: true
            })
            return false
          }
          return true
        }
      },
      {
        field: 'budget',
        headerName: 'Budget',
        editable: true,
        valueFormatter: (params) => {
          return SimpleHelper.priceFormatter(params.value)
        },
        cellClassRules: {
          'not-editable': (params) => {
            return params.value === 'inapplicable'
          }
        },
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'status',
        headerName: 'Status',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['active', 'archived'],
          valueFormatter: (params) => {
            if (params.value === 'active') {
              return 'Active'
            } else if (params.value === 'archived') {
              return 'Archived'
            }
            return params.value
          }
        }
      },
      {
        field: 'kick_off_date',
        headerName: 'Kick off Date',
        filter: 'agDateColumnFilter',
        cellRenderer: 'DatePickRenderer',
        cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
        cellClassRules: {
          'not-editable': (params) => {
            return params.value === 'inapplicable'
          }
        }
      },
      {
        field: 'real_launch_date',
        headerName: 'Launch Date',
        filter: 'agDateColumnFilter',
        cellClassRules: {
          'not-editable': (params) => {
            return params.value === 'inapplicable'
          }
        },
        cellRenderer: 'DatePickRenderer',
        cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 }
      },
      {
        field: 'cancellation_date',
        headerName: 'Cancellation Date',
        filter: 'agDateColumnFilter',
        cellRenderer: 'DatePickRenderer',
        cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
        cellClassRules: {
          'not-editable': (params) => {
            return params.value === 'inapplicable'
          }
        }
      },
      {
        field: 'note',
        tooltipField: 'note',
        headerName: 'Note',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true
      }
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['showArchived'] = this.$store.getters.getShowArchived(this.$route.name)
        MtApi.agGridProjectsSettings(params.request).then((response) => {
          this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = (params) => {
      const data = {
        event: 'update_project',
        field: {
          name: params.colDef.field,
          value: params.value
        }
      }
      MtApi.updateProject(params.data._id, data)
    }
  }
}
</script>
