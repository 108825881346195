<template>
  <div class="select-renderer" v-if="this.params.node.group === false">
    <v-select
      :class="[dynamicClass]"
      v-model="selectedOption"
      :options="options"
      :clearable="isClearable"
      :no-async="true"
      appendToBody
      v-on:input="onSelectChange"
    ></v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  data() {
    return {
      selectedOption: null,
      options: []
    }
  },
  mounted() {
    let options = this.params.options || [];
    const platform_options_field = this.params.platform_options_field;
    if (platform_options_field) {
      if (this.params.data?.platform_info[platform_options_field]) {
        options = this.params.data?.platform_info[platform_options_field]?.split(', ') || []; 
      }
      if (options.length) {
        options = options.map((o) => {
          return {
            label: o,
            value: o
          }
        })
      }
    }
    this.options = [{ value: null, label: 'Not set' }, ...options]
    this.selectedOption = this.options.find(
      (o) => o.value === this.params.value
    ) || this.options[0]
  },
  components: {
    vSelect
  },
  computed: {
    isClearable() {
      return this.selectedOption && this.selectedOption.value !== null;
    },
    dynamicClass() {
      if (this.selectedOption && (this.selectedOption?.value === null || this.selectedOption?.value === undefined || this.selectedOption?.value === '')) {
        return 'v-select-empty'
      }
      return ''
    }
  },
  methods: {
    onSelectChange(selectedValue) {
      const valueToSet = selectedValue ? selectedValue.value : null;
      this.params.node.setDataValue(
        this.params.colDef.field,
        valueToSet
      );
    }
  }
}
</script>
